.header_desktop {
    position: sticky;
    top: 0px;
    height: 120px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--secondary-color);
    z-index: 99;
    border-bottom: 1px rgb(214, 214, 214) solid;
}

.logo {
    height: 90px;
    margin: 0px 40px 0px 40px;
    cursor: pointer;
}

.container {
    display: flex;
    gap: 2rem;
    padding: 0px 3rem;
    transition: all .2s ease-in-out;
}

.item {
    font-size: 1rem;
    font-family: primary;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.item_active {
    font-size: 1rem;
    font-family: primary;
    cursor: pointer;
    border-bottom: 2px var(--primary-color) solid;
}

.item:hover {
    opacity: 50%;
}

.subitem {
    margin-left: 1rem;
    list-style: none;
    opacity: 70%;
    cursor: pointer;
    transition: all .2s ease-in-out;
    font-family: secondary;
    font-weight: lighter;
    font-size: .90rem;
}

.subitem_active {
    margin-left: 1rem;
    list-style: none;
    opacity: 100%;
    cursor: pointer;
    transition: all .2s ease-in-out;
    font-family: secondary;
    font-weight: lighter;
    font-size: .90rem;
    color: var(--primary-color);
    text-decoration: underline;
}

.subitem:hover {
    opacity: 90%;
}

.arrow {
    margin-left: .2rem;
}

.dropdown {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 180px;
    height: 160px;
    background-color: var(--secondary-color);
    box-shadow: 1px 4px 10px rgba(0, 0, 0, .7);
    position: absolute;
    border-radius: 5px;
    list-style: none;
    top: 80px;
}

@media(width<=1024px) {
    .header_desktop {
        display: none;
    }
}

@media(width<=1160px) {
    .container {
        gap: 1rem;
    }

    .item {
        font-size: .85rem;
    }
}