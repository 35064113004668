.blog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
     background-image: url(/public/Imagenes/Blog/header.PNG); 
    background-size: cover;
    padding: 3rem 0rem;
}

.header img {
    width: 100%;
}

.title {
    color: white;
    font-family: secondary;
    text-align: center;
    margin: 0rem 0rem 3rem 0rem;
    font-size: var(--primary-title-size);
}

.cards_container {
    width: 90vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
}

.form_container {
    padding: .5rem;
    width: 100%;
}

input,
textarea {
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    appearance: auto;
    -webkit-rtl-ordering: logical;
    cursor: text;
    border-color: rgb(226, 226, 226);
    background-color: (255, 255, 255);
    color: rgb(71, 71, 71);
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 4px lightgray solid;
    font-size: 14px;
    padding: 23px 16px 7px 16px;
    border-bottom-width: 4px;
    font-weight: 400;
    font-family: arial;
    resize: none;
}

textarea {
    padding: 23px 16px 5rem 16px;
}

.btn_send {
    width: 80%;
}

input {
    height: 3rem;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
    border: 1px black solid;
}

.btn_send {
    width: 100%;
    background-color: var(--primary-color);
    min-height: 56px;
    cursor: pointer;
    border: none;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.errMessage {
    font-family: secondary;
    font-size: .85rem;
    background-color: var(--primary-color);
    padding: .5rem;
}

@media(width>1024px) {
    .form_container {
        margin-top: 3rem;
        width: 60%;
    }

    .form {
        display: flex;
        flex-direction: column;
    }

    .input {
        width: 100%;
    }

    .btn_send {
        align-self: center;
        width: 30%;
    }
}