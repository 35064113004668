.success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    gap: 2rem;
}

.success h3 {
    font-size: 2rem;
    font-family: secondary;
    color: rgb(39, 39, 39);
    animation-name: slide-in;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    animation-iteration-count: 1;
    transform: translate(0, 0);
}

.success button {
    font-size: .75rem;
    border: none;
    background-color: var(--primary-color);
    padding: 0.5rem 1rem 0.5rem 1rem;
    cursor: pointer;
    transition: all .2s ease-in-out;
    font-family: primary;
    animation-name: appear;
    animation-timing-function: ease-in-out;
    animation-duration: 1s;
}

@keyframes slide-in {
    0% {
        transform: translate(-500%, 0);
    }

    100% {
        transform: translate(0, 0);
    }
}

@keyframes appear {
    0% {
        opacity: 0%;
    }

    100% {
        opacity: 100%;
    }
}