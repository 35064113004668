@font-face {
  font-family: primary;
  src: url(/src/assets/fonts/LEMONMILK-Light.otf);
}


@font-face {
  font-family: secondary;
  src: url(/src/assets/fonts/JosefinSans-Regular.ttf);
}

/* @font-face {
  font-family: terciary;
  src: url(/src/assets/fonts/LEMONMILK-Medium.otf);
} */

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-color: rgb(66, 169, 139);
  --secondary-color: white;
  --tercery-color: rgb(0, 0, 0);
  --default-padding: 0px 7rem;

  --default-bottom-padding: 3rem;
  --primary-title-size: 3rem;
  --secondary-title-size: 1.8rem;
}

a {
  text-decoration: none;
  color: var(--tercery-color);
}