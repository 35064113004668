.container_general {
    text-align: center;
    padding: .5rem;
    width: 100%;
    height: fit-content;
    letter-spacing: .1rem;
    margin-top: 3rem;
}

h4,
span {
    color: var(--primary-color);
}

input,
textarea {
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    appearance: auto;
    -webkit-rtl-ordering: logical;
    cursor: text;
    border-color: rgb(226, 226, 226);
    background-color: (255, 255, 255);
    color: rgb(71, 71, 71);
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 4px lightgray solid;
    font-size: 14px;
    padding: 23px 16px 7px 16px;
    border-bottom-width: 4px;
    font-weight: 400;
    font-family: arial;
    resize: none;
}

textarea {
    padding: 23px 16px 5rem 16px;
}

.btn_send {
    width: 80%;
}

input {
    height: 3rem;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
    border: 1px black solid;
}

.btn_send {
    width: 100%;
    background-color: var(--primary-color);
    min-height: 56px;
    cursor: pointer;
    border: none;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.title,
.subtitle,
legend {
    text-align: center;
    color: var(--primary-color);
    font-family: secondary;
    font-size: 2rem;
}

h4 {
    font-size: 34px;
}

h2 {
    padding-bottom: 2rem;
}

legend {
    padding-top: 3rem;
}

.text {
    text-align: center;
    color: rgb(87, 87, 87);
    font-size: 10px;
    font-weight: semibold;
}

.text h4,
.text a {
    padding-bottom: 1.5rem;
}

.errMessage {
    font-family: secondary;
    font-size: .85rem;
    background-color: var(--primary-color);
    padding: .5rem;
}

@media (width>1024px) {
    .two_col {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 2rem;
        padding: 3rem 0rem 3rem 0rem;
        height: fit-content;
    }

    .form {
        display: flex;
        flex-direction: column;
    }

    .form_container,
    text {
        width: 50%;
        height: fit-content;
    }

    .container_general {
        padding: var(--default-padding);
    }

    .title,
    legend {
        padding: 0;
        text-align: center;
        color: var(--primary-color);
        font-family: secondary;
        font-size: var(--primary-title-size);
    }

    .title {
        padding-bottom: 2rem;
    }

    .text {
        font-size: 24px;
        text-align: start;
        width: 50%;
    }

}