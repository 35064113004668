.capacitation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: secondary;
    padding: .5rem;
    margin-top: 3rem;
}

.presentation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgb(87, 87, 87);
    text-align: center;
    margin-bottom: var(--default-bottom-padding);
    margin-bottom: 6rem;
}

.title {
    color: var(--primary-color);
    font-size: 2rem;
    margin-bottom: 3rem;
    font-size: 2rem;
}

.course_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    color: rgb(87, 87, 87);
    gap: .1rem;
    margin-bottom: 6rem;
}

.course {
    width: 33%;
    min-width: 350px;
    margin-bottom: var(--default-bottom-padding);
}

.subtitle {
    color: rgb(39, 39, 39);
    margin-bottom: 1.5rem;
    text-align: center;
    height: 3rem;
}

.paragraph {
    padding: 0rem 1rem;
}

input,
textarea {
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    appearance: auto;
    -webkit-rtl-ordering: logical;
    cursor: text;
    border-color: rgb(226, 226, 226);
    background-color: (255, 255, 255);
    color: rgb(71, 71, 71);
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 4px lightgray solid;
    font-size: 14px;
    padding: 23px 16px 7px 16px;
    border-bottom-width: 4px;
    font-weight: 400;
    font-family: arial;
    resize: none;
}

textarea {
    padding: 23px 16px 5rem 16px;
}

.btn_send {
    width: 80%;
}

input {
    height: 3rem;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
    border: 1px black solid;
}

.btn_send {
    width: 100%;
    background-color: var(--primary-color);
    min-height: 56px;
    cursor: pointer;
    border: none;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.errMessage {
    font-family: secondary;
    font-size: .85rem;
    background-color: var(--primary-color);
    padding: .5rem;
}

@media (width>=1024px) {
    .capacitation {
        padding: var(--default-padding);
    }

    .title {
        font-size: var(--primary-title-size);
    }

    .form_container {
        width: 60%;
    }

    .form {
        display: flex;
        flex-direction: column;
    }

    .input {
        width: 100%;
    }

    .btn_send {
        align-self: center;
        width: 30%;
    }
}

@media (width>=560px) {
    .presentation {
        width: 60%;
    }
}