.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: fit-content;
    min-height: 290px;
    width: 100%;
    background-color: var(--tercery-color);
    z-index: 99;
}

.copyright {
    color: var(--secondary-color);
    opacity: 60%;
    font-family: secondary;
    font-size: .8rem;
    padding: 2rem 1rem;
    letter-spacing: .1rem;
    text-align: center;
}

.button_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0px 120px;
    margin-bottom: 6rem;
}

.item {
    color: var(--secondary-color);
    font-size: 1rem;
    font-family: primary;
    cursor: pointer;
    transition: all .2s ease-in-out;
    margin: .5rem 1.3rem;
}

.item:hover {
    color: var(--primary-color);
}

@media (width<630px) {
    .button_container {
        flex-direction: column;
        padding: 0px;
    }
}